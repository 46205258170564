import React from 'react';
import { Modal } from 'antd';
import { useModal } from './ModalContext';

const ModalComponent = () => {
  const { isModalVisible, modalImage, hideModal } = useModal();

  return (
    <Modal
      open={isModalVisible}
      onCancel={hideModal}
      footer={null}
      width="80%"
      styles={{ body: { padding: 0 } }}
    >
      <img alt="全图" src={modalImage} style={{ width: '100%', height: 'auto' }} />
    </Modal>
  );
};

export default ModalComponent;
