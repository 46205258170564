import React from 'react';
import { Row, Col, Card, Typography } from 'antd';
import DigitalHumanSlider from './DigitalHumanSlider'; // 确保正确导入 DigitalHumanSlider
import { renderCard } from '../utils'; // 导入 renderCard 函数

const { Title, Paragraph } = Typography;

const DigitalHuman = () => {
  return (
    <div id="digital-human">
      <Row gutter={[24, 24]} style={{ marginTop: 48 }}>
        <Col span={24}>
          <Card
            className="card-gradient-blue"
            style={{
              borderRadius: '15px'
            }}
          >
            <Title level={2} style={{ textAlign: 'center', color: '#009CFF', marginBottom: 24 }}>数字人技术展示</Title>
            <DigitalHumanSlider />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DigitalHuman;
