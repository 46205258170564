import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Card, Typography } from 'antd';
import Reveal from 'reveal.js';
import 'reveal.js/dist/reveal.css';
import 'reveal.js/dist/theme/black.css';

const { Title, Paragraph } = Typography;

const calculateRevealHeight = () => {
  const width = window.innerWidth; // 当前窗口宽度
  const height = (width * 9) / 16; // 根据宽度计算高度以保持 16:9 的比例
  return (height / window.innerHeight) * 100; // 转换为 vh
};

const HomeSection = () => {
  const revealRef = useRef(null);
  const [revealHeight, setRevealHeight] = useState(calculateRevealHeight());

  useEffect(() => {
    if (revealRef.current) {
      Reveal.initialize({
        controls: true,
        progress: true,
        center: true,
        hash: true,
        touch: {
          vertical: false,
          horizontal: true
        },
        mouseWheel: false,
        keyboard: {
          37: 'prev',
          39: 'next',
        },
        embedded: true,
        backgroundColor: 'transparent',
      });

      // 确保在初始化后调用 layout
      setTimeout(() => {
        Reveal.layout();
      }, 0);
    }
  }, [revealHeight]);

  useEffect(() => {
    const handleResize = () => {
      setRevealHeight(calculateRevealHeight());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderSlides = () => {
    const slides = [];
    for (let i = 1; i <= 48; i++) {
      slides.push(
        <section key={i}>
          <img src={`/iot-data-mid-center/幻灯片${i}.png`} alt={`Slide ${i}`} style={{ width: '100%', height: 'auto' }} />
        </section>
      );
    }
    return slides;
  };

  // 更新样式以确保 viewport 高度为 16:9
  const revealStyle = {
    width: '100%',
    height: `min(700px, max(400px, ${revealHeight}vh))`, // 设置样式自适应
    backgroundColor: 'transparent',
    position: 'relative', // 确保定位正确
  };

  return (
    <Row gutter={[24, 24]} justify="center" style={{ marginTop: 10 }}>
      <Col span={24}>
        <Card
          hoverable
          className="card-gradient-blue"
          style={{
            borderRadius: '15px',
            overflow: 'hidden'
          }}
        >
          <div
            ref={revealRef}
            className="reveal"
            style={revealStyle}
          >
            <div className="slides">
              {renderSlides()}
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default HomeSection;
