import React, { Suspense } from 'react';
import { Typography } from 'antd';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, useFBX, useAnimations, Environment, Grid, Html, useProgress } from '@react-three/drei';
import { useEffect, useRef } from 'react';
const { Title } = Typography;

// 添加加载进度条组件
function Loader() {
  const { progress } = useProgress();
  return (
    <Html center>
      <div style={{
        color: '#009CFF',
        fontSize: '1.5em',
        background: 'rgba(255, 255, 255, 0.8)',
        padding: '20px',
        borderRadius: '8px',
        textAlign: 'center'
      }}>
        加载中... {progress.toFixed(0)}%
      </div>
    </Html>
  );
}

// 3D模型组件
function Model({ url, onLoadAnimations }) {
  const fbx = useFBX(url);
  const modelRef = useRef();
  const { actions, names } = useAnimations(fbx.animations, fbx);
  const [currentAnimation, setCurrentAnimation] = React.useState('');

  useEffect(() => {
    const handleKeyPress = (event) => {
      const key = parseInt(event.key);
      const animationNames = names;
      
      if (!isNaN(key) && key > 0 && key <= animationNames.length) {
        if (currentAnimation) {
          actions[currentAnimation].stop();
        }
        
        const selectedAnimation = animationNames[key - 1];
        actions[selectedAnimation].reset().play();
        setCurrentAnimation(selectedAnimation);
        console.log('播放动画:', selectedAnimation);
      }
    };

    window.addEventListener('keypress', handleKeyPress);
    return () => window.removeEventListener('keypress', handleKeyPress);
  }, [actions, names, currentAnimation]);

  // 返回动画列表供父组件使用
  React.useEffect(() => {
    if (fbx.animations) {
      onLoadAnimations(names);
    }
  }, [fbx.animations, names, onLoadAnimations]);

  // 调整模型初始大小
  return <primitive 
    ref={modelRef} 
    object={fbx} 
    scale={0.05} // FBX 模型通常需要更小的缩放值
    position={[0, -3, 0]} // 稍微下移一点，使模型位于场景中心
  />;
}

// 3D场景组件
const Scene3D = ({ modelUrl, onLoadAnimations }) => {
  return (
    <div style={{ width: '100%', height: '600px' }}>
      <Canvas camera={{ position: [0, 2, 5], fov: 90 }}>
        <Suspense fallback={<Loader />}>
          <ambientLight intensity={0.5} />
          <directionalLight position={[5, 5, 5]} intensity={0.3} />
          <Environment preset="studio" background scale={0.5} />
          <Grid
            position={[0, -2, 0]}
            args={[20, 20]}
            cellSize={1}
            cellThickness={0.5}
            cellColor="#6f6f6f"
            sectionSize={5}
            fadeDistance={30}
            fadeStrength={1}
          />
          <Model url={modelUrl} onLoadAnimations={onLoadAnimations} />
          <OrbitControls minDistance={2} maxDistance={10} />
        </Suspense>
      </Canvas>
    </div>
  );
};

// 主要展示区块组件
const ThreeDemoSection = () => {
  const [animationList, setAnimationList] = React.useState([]);
  const modelUrl = '/models/1025.1.fbx';

  const handleLoadAnimations = (animations) => {
    setAnimationList(animations);
  };

  return (
    <section 
      id="3d-demo" 
      style={{ 
        padding: '40px 0',
        background: 'rgba(255, 255, 255, 0.8)',
        borderRadius: '10px',
        marginBottom: '40px'
      }}
    >
      <Title level={2} style={{ textAlign: 'center', color: '#009CFF', marginBottom: '30px' }}>
        数字直播间
      </Title>
      <div style={{ maxWidth: '1000px', margin: '0 auto', padding: '0 20px' }}>
        <Scene3D modelUrl={modelUrl} onLoadAnimations={handleLoadAnimations} />
        <div style={{ textAlign: 'center', marginTop: '20px', color: '#666' }}>
          使用鼠标拖拽查看不同角度
        </div>
        <div style={{ textAlign: 'center', marginTop: '20px', color: '#666' }}>
          <h3>可用动画列表：</h3>
          {animationList.map((name, index) => (
            <div key={name}>
              按 "{index + 1}" 播放: {name}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ThreeDemoSection;
