import { Card, Row, Col, Typography } from 'antd';
import smartHomeImage from '../assets/smart-home.png';
import parkImage from '../assets/smart-park.png';
import communityImage from '../assets/smart-community.png';
import smartOAImage from '../assets/smart-oa.png';
import smartCampusImage from '../assets/smart-campus.png';
import longRentImage from '../assets/long-rent.png';
import { renderCard } from '../utils'; // 导入 utils 中的函数
import { useModal } from '../ModalContext'; // 导入 useModal
const { Title } = Typography;

const CoreTech = () => {
    const { showModal } = useModal(); // 使用 useModal 获取状态和方法

    return (
        <div id="core-tech">
            <Row justify="center" style={{ marginTop: 48 }}>
                <Col span={24}>
                    <Card
                        className="card-gradient-cyan"
                        style={{
                            borderRadius: '15px'
                        }}
                    >
                        <Title level={2} style={{ textAlign: 'center', color: '#009CFF', marginBottom: 32 }}>
                            核心技术与解决方案
                        </Title>
                        <Row gutter={[24, 24]} justify="center">
                            {renderCard("智慧园区", "基于多模态感知和大数据分析的智慧园区解决方案，支持园区管理的数字化转型。", parkImage, showModal)}
                            {renderCard("长租公寓管理", "提供面向长租公寓的智慧管理平台，实现从租客入住到物业维护的全流程自动化管理。", longRentImage, showModal)}
                            {renderCard("智慧社区", "打造智能化、互联互通的社区，应用于出入管理、安防系统、公共区域服等。", communityImage, showModal)}
                            {renderCard("智慧行政", "优化行政流程，提高办效率，实现智化办公管理系统。", smartOAImage, showModal)}
                            {renderCard("智慧校园", "整合教学、管理、服务等多个方面，打造全方位的智慧化校园解决方案。", smartCampusImage, showModal)}
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default CoreTech;
