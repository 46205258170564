import React from 'react';
import { Carousel, Typography } from 'antd';
import metaHuman01 from '../assets/meta-human-01.png';
import metaHuman02 from '../assets/meta-human-02.png';

const { Paragraph } = Typography;

const DigitalHumanSlider = () => {
  return (
    <div>
      <Carousel autoplay>
        <div>
          <img src={metaHuman01} alt="数字人 1" style={{ width: '100%', height: 'auto' }} />
        </div>
        <div>
          <img src={metaHuman02} alt="数字人 2" style={{ width: '100%', height: 'auto' }} />
        </div>
      </Carousel>
      <Paragraph style={{ marginTop: '20px', textAlign: 'center' }}>
        数字人产品是基于AI技术的高度仿真虚拟实体，具备智能交互、情感模拟和灵活定制能力。
        适用于客服、教育、营销等多种场景，为企业提供创新解决方案，提升用户体验和工作效率。
      </Paragraph>
    </div>
  );
};

export default DigitalHumanSlider;
