import React from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { renderCard } from '../utils'; // 导入 renderCard
import iotImage from '../assets/iot-platform.png'; // 确保正确导入图片
import ruleEngineImage from '../assets/rule-engine.png';
import yewuGuanliImage from '../assets/yewu-guanli.png';
import { useModal } from '../ModalContext'; // 导入 useModal

const { Title, Paragraph } = Typography;

const Solutions = () => {
  const { showModal } = useModal(); // 使用 useModal 获取状态和方法

  const handleCardClick = (image) => {
    showModal(image); // 直接调用 showModal
  };

  return (
    <div id="solutions">
      <Row gutter={[24, 24]} style={{ marginTop: 48 }}>
        <Col span={24}>
          <Card
            className="card-gradient-light-blue"
            style={{
              borderRadius: '15px'
            }}
          >
            <Title level={2} style={{ textAlign: 'center', color: '#009CFF', marginBottom: 24, fontSize: '1.8rem' }}>物联数据中台</Title>
            <Paragraph style={{ textAlign: 'center', fontSize: '1rem', marginBottom: 32 }}>
              我们的物联网平台集成软硬件解决方案，提供丰富的设备生态和开放的标准接口，支持远程控制和本地部署，帮助客户轻松实现设备互联和智能化管理。
            </Paragraph>
            <Row gutter={[24, 24]} justify="center">
              {renderCard("设备管理平台", "支持自定义大屏展示设备详情及数据实时上报，提供直观的设备管理体验。", iotImage, handleCardClick)}
              {renderCard("规则引擎平台", "配置数据版本管理、定时事件和插件扩展能力，实现灵活的业务自动化。", ruleEngineImage, handleCardClick)}
              {renderCard("业务治理平台", "提供多种编程语言支持的BaaS平台，具备可视化数据库管理、统一登录系统及函数环境管理等功能。", yewuGuanliImage, handleCardClick)}
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Solutions;
