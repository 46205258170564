import React from 'react';
import { Card, Modal, Col, Typography } from 'antd';
import { useModal } from './ModalContext'; // 导入 useModal

const { Title, Paragraph } = Typography;

// 渲染卡片的函数
export const renderCard = (title, description, imageSrc, onClick) => (
  <Col xs={24} sm={12} md={8}>
    <Card
      hoverable
      cover={
        <img
          alt={title}
          src={imageSrc}
          style={{ height: '200px', objectFit: 'cover', cursor: 'pointer' }}
          onClick={() => (onClick ? onClick(imageSrc) : null)} // 传递点击事件，确保 onClick 有效
        />
      }
      style={{ height: 'auto', marginBottom: '20px' }}
    >
      <Title level={4}>{title}</Title>
      <Paragraph>{description}</Paragraph>
    </Card>
  </Col>
);

// 计算 Reveal 高度的函数
export const calculateRevealHeight = () => {
  const screenHeight = window.innerHeight;
  const headerHeight = 64; // 头部的高度
  const contentPadding = 48; // 内容区域的上下内边距
  const titleHeight = 100; // 标题和段落的估计高度

  return Math.max(300, screenHeight - headerHeight - contentPadding - titleHeight);
};

// 处理窗口大小变化的函数
export const handleResize = (setRevealHeight) => {
  setRevealHeight(calculateRevealHeight());
};

// 切换菜单可见性的函数
export const toggleMenu = (setMenuVisible) => {
  setMenuVisible(prev => !prev);
};

// 处理菜单点击的函数
export const handleMenuClick = (key, setCurrent, setMenuVisible) => {
  setCurrent(key);
  setMenuVisible(false);
  // 处理滚动逻辑...
};
