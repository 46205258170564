// A single-page application (SPA) for Hangzhou Dayazhitang Information Systems Co., Ltd.
// This design uses React and Ant Design (antd) with a strong focus on technology visuals and special effects.

import React, { useState } from 'react';
import { Layout, Menu, Typography, Row, Col, Button, Drawer } from 'antd';
import { HomeOutlined, RocketOutlined, AppstoreOutlined, InfoCircleOutlined, UserOutlined, MenuOutlined } from '@ant-design/icons';
import './App.css'; // Custom CSS for special effects and visual style
import './responsive.css';
import ParticleBackground from 'react-particle-backgrounds';
import 'reveal.js/dist/reveal.css';
import 'reveal.js/dist/theme/black.css';
import logo from './assets/logo.png'; // 导入 logo 图
import './cardStyles.css'; // 导入新的 CSS 文件
import DigitalHuman from './components/DigitalHuman'; // 导入 DigitalHuman 组件
import { Helmet } from 'react-helmet';
import { ModalProvider } from './ModalContext'; // 导入 ModalProvider

import HomeSection from './components/HomeSection';
import CoreTech from './components/CoreTech';
import Solutions from './components/Solutions'; // 导入 Solutions 组件
import AboutUs from './components/AboutUs'; // 导入 AboutUs 组件
import ModalComponent from './ModalComponent'; // 导入 ModalComponent
import ThreeDemoSection from './components/ThreeDemoSection'; // 导入 ThreeDemoSection 组件
const { Header, Content, Footer } = Layout;
const { Title, Paragraph, Text, Link } = Typography;

const calculateRevealHeight = () => {
  const screenHeight = window.innerHeight;
  const headerHeight = 64; // 头部的高度
  const contentPadding = 48; // 内容区域的上下内边距
  const titleHeight = 100; // 标题和段落的估计高度

  // 计算 reveal 的高度,确保它不会太小
  return Math.max(300, screenHeight - headerHeight - contentPadding - titleHeight);
};

const App = () => {
  const [current, setCurrent] = useState('home');
  const [menuVisible, setMenuVisible] = useState(false);

  const particleSettings = {
    canvas: {
      canvasFillSpace: true,
      width: 200,
      height: 200,
      useBouncyWalls: false
    },
    particle: {
      particleCount: 100,
      color: '#009CFF',
      minSize: 1,
      maxSize: 3
    },
    velocity: {
      directionAngle: 0,
      directionAngleVariance: 360,
      minSpeed: 0.1,
      maxSpeed: 0.3
    },
    opacity: {
      minOpacity: 0,
      maxOpacity: 0.3,
      opacityTransitionTime: 5000
    }
  };

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleMenuClick = ({ key }) => {
    setCurrent(key);
    setMenuVisible(false); // 点击菜单项后关闭抽屉
    if (key === 'home') {
      setTimeout(() => {
        // const homeCard = document.querySelector('.site-layout-content .ant-row');
        const homeCard = document.querySelector('main');
        if (homeCard) {
          homeCard.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 0);
    } else {
      // 对于其他菜单项，保持原有的滚动逻辑
      const element = document.getElementById(key);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const menuItems = [
    { key: 'home', label: '首页', icon: <HomeOutlined />, style: { color: '#003366' } },
    { key: '3d-demo', label: '数字直播间', icon: <AppstoreOutlined />, style: { color: '#003366' } },
    { key: 'digital-human', label: '数字人', icon: <UserOutlined />, style: { color: '#003366' } },
    { key: 'core-tech', label: '核心技术', icon: <RocketOutlined />, style: { color: '#003366' } },
    { key: 'solutions', label: '解决方案', icon: <AppstoreOutlined />, style: { color: '#003366' } },
    { key: 'about', label: '关于我们', icon: <InfoCircleOutlined />, style: { color: '#003366' } },
  ];

  return (
    <ModalProvider>
      <Helmet>
        <title>杭州大雅智堂信息系统有限公司 - 智能物联网解决方案提供商</title>
        <meta name="description" content="杭州大雅智堂专物联网与智能化的智慧系统设计与研发,提供智慧园区、长租公寓管理、智慧社区等解决方案,赋能未来生活。" />
        <meta name="keywords" content="大雅智堂,物联网,智能化,智慧系统,智慧园区,长租公寓管理,智慧社区" />
        <meta name="author" content="杭州大雅智堂信息系统有限公司" />
        <link rel="canonical" href="https://www.dayazhitang.com" />
      </Helmet>
      <Layout className="layout">
        {/* 粒子背景 */}
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: -1 }}>
          <ParticleBackground settings={particleSettings} />
        </div>

        {/* 头部 */}
        <Header style={{
          position: 'fixed',
          zIndex: 1,
          width: '100%',
          padding: '0 20px',
          background: 'rgba(255, 255, 255, 0.95)',
          boxShadow: '0 2px 8px rgba(0, 156, 255, 0.1)'
        }}>
          <Row justify="space-between" align="middle" style={{ height: '100%' }}>
            <Col>
              <Row align="middle" gutter={16}>
                <Col>
                  <img
                    src={logo}
                    alt="杭州大雅智堂信息系统有限公司 Logo"
                    style={{ height: '2em', verticalAlign: 'middle' }}
                  />
                </Col>
                <Col>
                  <Typography.Title level={3} style={{ margin: 0, color: '#009CFF', fontSize: '1.2rem' }}>
                    大雅智堂
                  </Typography.Title>
                </Col>
              </Row>
            </Col>
            <Col>
              <Button type="primary" onClick={toggleMenu} style={{ display: 'none' }} className="menu-button">
                <MenuOutlined />
              </Button>
              <Menu
                onClick={handleMenuClick}
                selectedKeys={[current]}
                mode="horizontal"
                style={{ background: 'transparent', borderBottom: 'none', display: 'none' }}
                className="desktop-menu"
              >
                {menuItems.map(item => (
                  <Menu.Item key={item.key} icon={item.icon} style={{ color: '#009CFF' }}>
                    {item.label}
                  </Menu.Item>
                ))}
              </Menu>
            </Col>
          </Row>
        </Header>

        {/* 移动端菜单抽屉 */}
        <Drawer
          title="菜单"
          placement="right"
          onClose={toggleMenu}
          visible={menuVisible}
          bodyStyle={{ padding: 0 }}
        >
          <Menu
            onClick={handleMenuClick}
            selectedKeys={[current]}
            mode="vertical"
            style={{ borderRight: 'none' }}
          >
            {menuItems.map(item => (
              <Menu.Item key={item.key} icon={item.icon} style={{ color: '#009CFF' }}>
                {item.label}
              </Menu.Item>
            ))}
          </Menu>
        </Drawer>

        {/* 主要内容 */}
        <Content style={{ padding: '0 20px', marginTop: 64 }}>
          <main className="site-layout-content" style={{ maxWidth: '1200px', margin: '24px auto', minHeight: '100vh' }}>

            <Title level={1} style={{ color: '#009CFF', textAlign: 'center', marginBottom: 24 }}>
              杭州大雅智堂
            </Title>
            <Paragraph style={{ color: '#333', textAlign: 'center', fontSize: '18px' }}>
              杭州大雅智堂信息系统有限公司，致力于物联网与智能化的智慧系统设计与研发，赋能未来生活。
            </Paragraph>

            {/* 首页部分 */}
            <HomeSection />

            {/* 3D 演示部分 */}
            <ThreeDemoSection />

            {/* 数字人板块 */}
            <DigitalHuman />

            {/* 核心技术与解决方案部分 */}
            <CoreTech />

            {/* 物联数据中台部分 */}
            <Solutions />

            {/* 关于我们部分 */}
            <AboutUs />
          </main>
        </Content>

        {/* 页脚 */}
        <Footer style={{
          textAlign: 'center',
          background: '#f0f2f5',
          padding: '24px 20px',
          borderTop: '1px solid #e8e8e8'
        }} id="contact">
          <Row justify="center" align="middle" gutter={[16, 8]}>
            <Col span={24}>
              <Text strong style={{ color: '#009CFF', fontSize: '16px', marginRight: '8px' }}>
                杭州大雅智堂信息系统有限公司 ©2024
              </Text>
              <Link
                href="https://beian.miit.gov.cn"
                target="_blank"
                style={{ color: '#009CFF', fontSize: '16px' }}
              >
                浙ICP备2020043527号
              </Link>
            </Col>
            <Col span={24}>
              <Text style={{ color: '#666', fontSize: '14px' }}>
                创造智能化科技生活体验
              </Text>
            </Col>
          </Row>
        </Footer>

        {/* 模态框 */}
        <ModalComponent />
      </Layout>
    </ModalProvider>
  );
};

export default App;
