import React from 'react';
import { Row, Col, Card, Typography } from 'antd';
import dingdingQRCode from '../assets/dingding-qrcode.png'; // 确保正确导入二维码图片

const { Title, Paragraph } = Typography;

const AboutUs = () => {
  return (
    <div id="about">
      <Row gutter={[16, 16]} className="about-us-section" style={{ marginTop: 48 }}>
        <Col span={24}>
          <Card
            className="card-gradient-green"
            style={{
              borderRadius: '15px',
              boxShadow: '0 4px 12px rgba(0, 156, 255, 0.1)'
            }}
          >
            <Title level={2} style={{ textAlign: 'center', color: '#009CFF', marginBottom: 32 }}>关于我们</Title>
            <Row gutter={[32, 32]} justify="center" align="middle">
              <Col xs={24} sm={12}>
                <img
                  src={dingdingQRCode}
                  alt="钉钉二维码"
                  style={{
                    maxWidth: '200px',
                    display: 'block',
                    margin: '0 auto',
                    borderRadius: '10px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                  }}
                />
                <Paragraph style={{ textAlign: 'center', marginTop: '16px', fontSize: '16px', color: '#333' }}>
                  钉钉扫码联系我们
                </Paragraph>
              </Col>
              <Col xs={24} sm={12}>
                <Title level={3} style={{ color: '#009CFF' }}>杭州大雅智堂信息系统有限公司</Title>
                <Paragraph style={{ fontSize: '16px', color: '#333' }}>
                  <strong>邮箱：</strong> cuizheng@zhiping.tech
                </Paragraph>
                <Paragraph style={{ fontSize: '16px', color: '#333' }}>
                  <strong>地址：</strong> 杭州市余杭区阿里巴巴西溪园区 B 区二号楼
                </Paragraph>
                <Paragraph style={{ fontSize: '16px', color: '#333', marginTop: '16px' }}>
                  我们致力于为客户提供最先进的物联网和智能化解决方案助力企业数字化转型，创造更智能、更高效的未来。
                </Paragraph>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AboutUs;
